import { render, staticRenderFns } from "./bhgcxczp.vue?vue&type=template&id=54ab9321&"
import script from "./bhgcxczp.vue?vue&type=script&lang=js&"
export * from "./bhgcxczp.vue?vue&type=script&lang=js&"
import style0 from "./bhgcxczp.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports