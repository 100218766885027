<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">现场照片</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    prop="GCXMID"
                    label="项目名称"
                    class="from-item-block"
                >
                    <el-select
                        v-model="formdata.dataDic.GCXMID"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in BhgcxmList"
                            :key="`${index}`"
                            :value="item.ID"
                            :label="item.GCMC"
                            :disabled="!isEdit"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="JCWZMC"
                    label="监测点"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.JCWZMC"
                        :disabled="!isEdit"
                        :maxlength="50"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="SM"
                    label="现场情况"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.SM"
                        type="textarea"
                        :disabled="!isEdit"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="SJWGSJ"
                    label="照片"
                    class="from-item-block"
                >
                    <basic-upload
                        ref="uploadFile"
                        :isEdit="isEdit"
                        :itemId="itemId"
                        :metaData.sync="formdata.metaJson"
                        basicType="picture"
                        :disabled="!isEdit"
                    ></basic-upload>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "bhgcxczp",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "1503",
                dataDic: {
                    GCXMID: "",
                    SM: "",
                },
            },
            rules: {
                GCXMID: [
                    {
                        required: true,
                        message: "请选择项目名称",
                        trigger: "change",
                    },
                ],
            },
            BhgcxmList: [], // 保护工程项目
        };
    },
    mounted() {
        this.getBhgcxmList();
    },
    methods: {
        ...mapActions(["GetGLXList"]),
        // 获得考古项目列表
        async getBhgcxmList() {
            let res = await this.GetGLXList({
                itemcode: "1501",
                lm: "jcsj",
            });
            this.BhgcxmList = res.ResultValue || [];
        },
    },
};
</script>

<style>
.yearBox .yearItem {
    width: 48% !important;
}
.yearBox .separate {
    padding: 0 10px;
}
</style>
